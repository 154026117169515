<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" v-if="estaActivo" :clipped="$vuetify.breakpoint.lgAndUp" app>
      <v-list dense>
        <template v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
          <v-list-item :to="{ name: 'dashboard' }">
            <v-list-item-icon>
              <v-icon>dashboard</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Dashboard</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Ventas -->
        <template v-if="esAdministrador || esVendedor || esCaja">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>local_mall</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Ventas</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'ventas' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Listado Ventas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>article</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'pedido_clientes' }" v-if="esAdministrador || esVendedor || esCaja">
              <v-list-item-content>
                <v-list-item-title>Pedidos/Proformas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>assignment</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cuentas_x_cobrar' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Cuentas x Cobrar</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-group sub-group no-action v-if="esAdministrador || esCaja">
              <template v-slot:activator>
                <v-list-item-content>
                  <v-list-item-title>Resúmenes y Anulaciones</v-list-item-title>
                </v-list-item-content>
              </template>
              <v-list-item :to="{ name: 'resumen_diario' }" v-if="esAdministrador || esCaja">
                <v-list-item-content>
                  <v-list-item-title>Resúmenes</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>fact_check</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-list-item :to="{ name: 'baja' }" v-if="esAdministrador || esCaja" color="error">
                <v-list-item-content>
                  <v-list-item-title>Anulaciones</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon color="error">dangerous</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </v-list-group>
          </v-list-group>
        </template>
        <!-- Fin Ventas -->

        <!-- Compras -->
        <template v-if="esAdministrador || esAlmacenero || esCaja">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>public</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Compras</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'compras' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Listado Compras</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>article</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'pedido_proveedores' }" v-if="esAdministrador || esAlmacenero || esCaja">
              <v-list-item-content>
                <v-list-item-title>Pedido Proveedores</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>assignment</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cuentas_x_pagar' }" v-if="esAdministrador || esCaja">
              <v-list-item-content>
                <v-list-item-title>Cuentas x Pagar</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Compras -->

        <!-- Personas -->
        <template v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
          <v-list-item :to="{ name: 'personas' }">
            <v-list-item-icon>
              <v-icon>business_center</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>Clientes y Proveedores</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </template>
        <!-- Fin Personas -->

        <!-- Producto -->
        <template v-if="esAdministrador">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>category</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Productos</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'productos' }">
              <v-list-item-content>
                <v-list-item-title>Listado Productos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>article</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'categorias' }">
              <v-list-item-content>
                <v-list-item-title>Categorías</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>apps</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item :to="{ name: 'marcas' }">
              <v-list-item-content>
                <v-list-item-title>Marcas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>bookmarks</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item :to="{ name: 'cambioprecios' }">
              <v-list-item-content>
                <v-list-item-title>Cambio de Precios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>price_change</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Producto -->

        <!-- Inventario -->
        <template v-if="esAdministrador || esAlmacenero">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>inventory</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Inventario</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'traslado' }">
              <v-list-item-content>
                <v-list-item-title>Traslados</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>sync_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'ajustesstock' }">
              <v-list-item-content>
                <v-list-item-title>Ajustes Stock</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>rule</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'stock_productos' }">
              <v-list-item-content>
                <v-list-item-title>Stock Productos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'kardex' }">
              <v-list-item-content>
                <v-list-item-title>Kardex</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Inventario -->

        <!-- Guías de Remisión -->
        <template v-if="esAdministrador || esCaja">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>article</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Guía de Remisión</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'guias_remision' }">
              <v-list-item-content>
                <v-list-item-title>GRE Remitente</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>receipt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'conductor' }">
              <v-list-item-content>
                <v-list-item-title>Conductores</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>group</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'transportista' }">
              <v-list-item-content>
                <v-list-item-title>Transportistas</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>local_shipping</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'vehiculo' }">
              <v-list-item-content>
                <v-list-item-title>Vehiculos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>emoji_transportation</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Guías de Remisión -->

        <!-- Caja -->
        <template v-if="esAdministrador || esCaja">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>local_atm</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Caja</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'caja' }">
              <v-list-item-content>
                <v-list-item-title>Movimientos Caja</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>attach_money</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'cierre_diario' }">
              <v-list-item-content>
                <v-list-item-title>Cierre Diario</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>power_off</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Caja -->

        <!-- Mantenimiento -->
        <template v-if="esAdministrador">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>settings_suggest</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Mantenimiento</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'empresas' }">
              <v-list-item-content>
                <v-list-item-title>Empresa</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>home_work</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'establecimientos' }">
              <v-list-item-content>
                <v-list-item-title>Establecimientos</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'usuarios' }">
              <v-list-item-content>
                <v-list-item-title>Usuarios</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>supervised_user_circle</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'roles' }">
              <v-list-item-content>
                <v-list-item-title>Roles</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>verified_user</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'unidad_medidas' }">
              <v-list-item-content>
                <v-list-item-title>Unidad Medida</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>compare_arrows</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Mantenimiento -->

        <!-- Reportes -->
        <template v-if="estaActivo">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>print</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Reportes</v-list-item-title>
              </v-list-item-content>
            </template>
            <template v-if="esAdministrador || esCaja">
              <v-list-item :to="{ name: 'movimiento_diario' }">
                <v-list-item-content>
                  <v-list-item-title>Movimiento Diario</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
            </template>
            <v-list-item :to="{ name: 'venta_usuarios' }" v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
              <v-list-item-content>
                <v-list-item-title>Ventas Usuario</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'compras_ventas' }" v-if="esAdministrador || esAlmacenero || esVendedor || esCaja">
              <v-list-item-content>
                <v-list-item-title>Mov. x Persona</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-divider />
            <template v-if="esAdministrador || esAlmacenero">
              <v-list-item :to="{ name: 'compras_detalladas' }">
                <v-list-item-content>
                  <v-list-item-title>Compras Detalladas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <template v-if="esAdministrador">
              <v-list-item :to="{ name: 'ventas_detalladas' }">
                <v-list-item-content>
                  <v-list-item-title>Ventas Detalladas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
            <template v-if="esAdministrador || esCaja">
              <v-list-item :to="{ name: 'detalle_cuentas' }">
                <v-list-item-content>
                  <v-list-item-title>Detalle de Cuentas</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
              <v-divider />
            </template>
            <template v-if="esAdministrador">
              <v-list-item :to="{ name: 'movimientos_tipo' }">
                <v-list-item-content>
                  <v-list-item-title>Tipo Movimiento</v-list-item-title>
                </v-list-item-content>
                <v-list-item-icon>
                  <v-icon>list_alt</v-icon>
                </v-list-item-icon>
              </v-list-item>
            </template>
          </v-list-group>
        </template>
        <!-- Fin Reportes -->

        <!-- Resumenes -->
        <template v-if="esAdministrador">
          <v-list-group no-action>
            <template slot="activator">
              <v-list-item-action>
                <v-icon>summarize</v-icon>
              </v-list-item-action>
              <v-list-item-content>
                <v-list-item-title>Resúmenes</v-list-item-title>
              </v-list-item-content>
            </template>
            <v-list-item :to="{ name: 'estadistica_mensual' }">
              <v-list-item-content>
                <v-list-item-title>Estadística Mensual</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item :to="{ name: 'contabilidadresumen' }" v-if="esAdministrador">
              <v-list-item-content>
                <v-list-item-title>Contabilidad</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon>list_alt</v-icon>
              </v-list-item-icon>
            </v-list-item>
          </v-list-group>
        </template>
        <!-- Fin Resumenes -->
      </v-list>
    </v-navigation-drawer>

    <v-app-bar :clipped-left="$vuetify.breakpoint.lgAndUp" app
      :color="datos.color ? $vuetify.theme.themes.light.primary = datos.color : $vuetify.theme.themes.light.primary" dark
      :dense="estaActivo">
      <!-- Logo y titulo -->
      <v-toolbar-title style="width: 300px" class="ml-0">
        <v-app-bar-nav-icon @click.stop="drawer = !drawer" v-if="estaActivo"></v-app-bar-nav-icon>
        <router-link to="/">
          <v-avatar tile size="35px" class="mx-1" large item>
            <v-img :src="require('./assets/logo.png')" alt="Vuetify" />
          </v-avatar>
        </router-link>
        <span v-if="estaActivo" class="font-weight-black">{{ datos.sucursal }}</span>
        <span v-else class="font-weight-black">UrpiFact</span>
      </v-toolbar-title>
      <!-- Fin Logo y titulo -->

      <!-- Accesos directos -->
      <v-spacer />
      <template v-if="esAdministrador || esCaja || esVendedor">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on" class="mx-1">
              <v-icon @click="irPedidoCliente" v-if="esVendedor">local_mall</v-icon>
              <v-icon @click="irVenta" v-else-if="esAdministrador || esCaja">local_mall</v-icon>
            </v-btn>
          </template>
          <span>Nueva Venta</span>
        </v-tooltip>
      </template>
      <template v-if="esAdministrador || esCaja || esAlmacenero">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon @click="irPedidoProveedor" v-if="esAlmacenero">public</v-icon>
              <v-icon @click="irCompra" v-else-if="esAdministrador || esCaja">public</v-icon>
            </v-btn>
          </template>
          <span>Nueva Compra</span>
        </v-tooltip>
      </template>
      <template v-if="esAdministrador || esCaja">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn icon v-bind="attrs" v-on="on">
              <v-icon @click="irGuia">text_snippet</v-icon>
            </v-btn>
          </template>
          <span>Nueva Guía de Remisión</span>
        </v-tooltip>
      </template>
      <template v-if="esAdministrador || esCaja">
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn class="hidden-sm-and-down" icon v-bind="attrs" v-on="on">
              <v-icon @click="irCaja">local_atm</v-icon>
            </v-btn>
          </template>
          <span>Caja</span>
        </v-tooltip>
      </template>
      <v-spacer />
      <template v-if="estaActivo">
        <v-chip :color="$vuetify.theme.themes.light.primary" class="hidden-sm-and-down" :disabled="disabletc"
          @click="updateSetting">
          TC: {{ parseFloat(datos.tc) }}
        </v-chip>
      </template>
      <v-spacer />
      <!-- Fin Accesos directos -->

      <!-- Link -->
      <v-toolbar-items class="hidden-sm-and-down" v-if="!estaActivo">
        <v-btn v-for="item, i in menu" :key="i" :to="item.link" text>{{ item.name }}</v-btn>
      </v-toolbar-items>

      <v-menu>
        <template v-slot:activator="{ on }">
          <v-btn class="hidden-md-and-up" v-if="!estaActivo" v-on="on" text fab>
            <v-icon>menu</v-icon>
          </v-btn>
        </template>
        <v-list dense>
          <v-list-item v-for="item, i in menu" :key="i" :to="item.link">
            <v-list-item-content>
              <v-list-item-title>{{ item.name }}</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Fin Link -->

      <!-- Notificaciones -->
      <v-menu bottom left v-model="menuNotifCpe" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="esAdministrador || esCaja">
        <template v-slot:activator="{ on }">

          <v-btn icon v-on="on" @click="cargarNotifCpes()">
            <v-badge :content="notifcpes.length" :value="notifcpes.length" color="red" overlap>
              <v-icon @click="">notification_important</v-icon>
            </v-badge>
          </v-btn>

        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="error">warning</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>COMPROBANTES NO ENVIADOS</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(notifcpes, i) in notifcpes" :key="i">
            <!-- <v-list-item-icon>
              <v-icon small color="amber">description</v-icon>
            </v-list-item-icon> -->
            <v-list-item-content>
              <v-list-item-title> {{ notifcpes.serie_comprobante }}-{{ notifcpes.num_comprobante }} | {{ notifcpes.fecha |
                formatearFecha }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title class="orange--text">{{ notifcpes.estado }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>

      <v-menu bottom left v-model="menuNotificacion" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="esAdministrador || esCaja">
        <template v-slot:activator="{ on }">

          <v-btn icon v-on="on" class="mr-2" @click="cargarNotificaciones()">
            <v-badge :content="notificaciones.length" :value="notificaciones.length" color="orange" overlap>
              <v-icon>notifications_active</v-icon>
            </v-badge>
          </v-btn>

        </template>
        <v-list dense>
          <v-list-item>
            <v-list-item-icon>
              <v-icon color="primary">payments</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>CUENTAS POR PAGAR Y COBRAR</v-list-item-title>
            </v-list-item-content>
          </v-list-item>

          <v-list-item v-for="(notificaciones, i) in notificaciones" :key="i">
            <v-list-item-icon>
              <v-icon small :color="notificaciones.fecha_vencimiento < fecha_actual ? 'error' : 'info'"
                @click="verNotificacion(notificaciones.idtipo_movimiento), (menuNotificacion = false)">{{
                  notificaciones.idtipo_movimiento == 1 ? "remove_circle_outline" : "add_circle_outline"
                }}</v-icon>
            </v-list-item-icon>
            <v-list-item-content>
              <v-list-item-title>{{ notificaciones.persona.substr(0, 22) }}</v-list-item-title>
            </v-list-item-content>
            <v-list-item-action>
              <v-list-item-title>{{ notificaciones.moneda == "S" ? "S/" : "$" }} {{ notificaciones.importe |
                formatearNumero
              }}</v-list-item-title>
            </v-list-item-action>
          </v-list-item>
        </v-list>
      </v-menu>
      <!-- Fin Notificaciones -->

      <!-- Cerrar sesion -->
      <v-menu bottom left v-model="menuSesion" :close-on-content-click="false" :nudge-width="200" offset-y
        v-if="estaActivo">
        <template v-slot:activator="{ on }">
          <v-btn icon v-on="on">
            <v-avatar size="35px" item>
              <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
            </v-avatar>
          </v-btn>
        </template>

        <v-card>
          <v-list dense>
            <v-list-item>
              <v-list-item-avatar>
                <v-avatar size="40px" item>
                  <img :src="'data:image/png;base64,' + avatar" alt="Vuetify" />
                </v-avatar>
              </v-list-item-avatar>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.nombre }}</v-list-item-title>
                <v-list-item-subtitle> {{ usuario.rol }}</v-list-item-subtitle>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon @click="$vuetify.theme.dark = !$vuetify.theme.dark" v-if="estaActivo">contrast</v-icon>
              </v-list-item-icon>
            </v-list-item>

            <v-list-item>
              <v-list-item-icon>
                <v-icon>email</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ usuario.correo }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>store</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title>{{ datos.empresa.split(' ')[0] }} | {{ datos.sucursal }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-divider />
            <v-list-item>
              <v-list-item-icon>
                <v-icon>manage_history</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text">IGV: {{ parseFloat(datos.igv) * 100 + "%"
                }}</v-list-item-title>
              </v-list-item-content>
              <v-list-item-icon>
                <v-icon v-if="datos.formatimpresion == '1'" @click="cargarSettings">receipt</v-icon>
                <v-icon v-else-if="datos.formatimpresion == '2'" @click="cargarSettings">description</v-icon>
              </v-list-item-icon>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>send</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text">SUNAT {{ datos.cpe }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
            <v-list-item>
              <v-list-item-icon>
                <v-icon>card_membership</v-icon>
              </v-list-item-icon>
              <v-list-item-content>
                <v-list-item-title class="deep-orange--text">VCTO CERT: {{ datos.vcto_certificado }}</v-list-item-title>
              </v-list-item-content>
            </v-list-item>
          </v-list>
          <v-card-actions>
            <v-spacer />
            <v-btn small block color="error" @click="salir(), (menuSesion = false)" outlined>Cerrar sesión</v-btn>
          </v-card-actions>
        </v-card>
      </v-menu>
      <!-- Fin Cerrar sesion -->
    </v-app-bar>
    <!-- Contenido -->
    <v-main>
      <v-slide-y-transition mode="out-in">
        <router-view />
      </v-slide-y-transition>
    </v-main>
    <!-- Fin Contenido -->
    <!-- Snackbar -->
    <v-snackbar v-model="snackbar.active" :color="snackbar.color" :timeout="snackbar.timeout"
      transition="slide-y-reverse-transition" right>
      <v-layout align-center pr-4>
        <v-icon class="pr-3" large>{{ snackbar.icon }}</v-icon>
        <v-layout column>
          <div>{{ snackbar.message }}</div>
        </v-layout>
      </v-layout>
      <template v-slot:action="{ attrs }">
        <v-btn icon v-bind="attrs" @click="snackbar.active = false">
          <v-icon>clear</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
    <!-- Fin Snackbar -->
    <!-- Pie de Pagina -->
    <v-footer padless v-if="!estaActivo">
      <v-row no-gutters class="primary text-center">
        <v-col cols="12">
          <div class="font-weight-regular text-h7 white--text mt-4">
            <a class="white--text" target="_blank" href="https://wa.me/+51930984158" style="text-decoration:none"><v-icon
                dark class="mb-1 mx-1">whatsapp</v-icon>+51930984158</a>
            <a class="white--text" target="_blank" href="https://www.facebook.com/urpisoft"
              style="text-decoration:none"><v-icon dark class="mb-1 mx-1">facebook</v-icon></a>
          </div>
          <div class="font-weight-regular text-h7 white--text">
            <v-icon dark class="mb-1 mx-1">location_on</v-icon>Jr. Los Pinos Mz. D Lt. 4, Urb. La Florida , Juliaca.
          </div>
          <div class="font-weight-regular text-h7 white--text mb-4">
            <v-icon dark class="mb-1 mx-1">email</v-icon>efrain@urpisoft.pe
          </div>
        </v-col>
      </v-row>
      <v-card-text class="primary darken-2 text-center white--text">Copyright © UrpiFact {{ new Date().getFullYear() }}
        Todos los derechos reservados. v5.4.41</v-card-text>
    </v-footer>
    <!-- Fin Pie de Pagina -->
  </v-app>
</template>

<script>
import moment from "moment";
import axios from "axios";
import { mapActions, mapGetters, mapState } from "vuex";

export default {
  name: "App",
  data() {
    return {
      headers: [
        { text: "NOMBRES", value: "persona" },
        { text: "IMPORTE", value: "importe" },
      ],
      drawer: null,
      menuSesion: false,
      menuNotificacion: false,
      menuNotifCpe: false,
      fecha_actual: moment().format("YYYY-MM-DD"),
      disabletc: false,

      menu: [
        { link: "inicio", name: "Inicio" },
        { link: "soluciones", name: "Soluciones" },
        { link: "login", name: "Login" },
      ],
    };
  },

  computed: {
    ...mapState(["usuario", "datos", "avatar", "notificaciones", "notifcpes", "snackbar"]),
    ...mapGetters([
      "estaActivo",
      "esAdministrador",
      "esAlmacenero",
      "esVendedor",
      "esCaja",
    ]),
  },

  created() {
    this.autoLogin();
  },

  methods: {
    ...mapActions([
      "salir",
      "autoLogin",
      "cargarNotificaciones",
      "cargarNotifCpes",
      "cargarSettings",
    ]),

    irPedidoProveedor() {
      this.$router.push({ name: "pedido_proveedores" }).catch(() => { });
    },
    irPedidoCliente() {
      this.$router.push({ name: "pedido_clientes" }).catch(() => { });
    },
    irCompra() {
      this.$router
        .push({
          name: "compras",
          params: {
            adcompra: true,
          },
        })
        .catch(() => { });
    },
    irVenta() {
      this.$router
        .push({
          name: "ventas",
          params: {
            adventa: true,
          },
        })
        .catch(() => { });
    },
    irGuia() {
      this.$router
        .push({
          name: "guias_remision",
          params: {
            adguia: true,
          },
        })
        .catch(() => { });
    },
    irCaja() {
      this.$router.push({ name: "caja" }).catch(() => { });
    },

    updateSetting() {
      let me = this;
      me.disabletc = true,

        axios.get("api/Movimientos/ConsultaTipoCambio")
          .then(function (response) {
            me.cargarSettings();
            me.disabletc = false;
          })
          .catch(function (error) { me.disabletc = false; });
    },

    verNotificacion(idtipo_movimiento) {
      let me = this;
      if (idtipo_movimiento == 1) {
        me.$router
          .push({
            name: "cuentas_x_pagar",
            params: {
              cuentaxpagar: true,
            },
          })
          .catch(() => { });
      } else {
        me.$router
          .push({
            name: "cuentas_x_cobrar",
            params: {
              cuentaxcobrar: true,
            },
          })
          .catch(() => { });
      }
    },
  },
};
</script>
<style lang="sass">
@import '../node_modules/typeface-roboto/index.css'
</style>